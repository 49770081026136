import locale77526775 from '../../i18n/fr.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"fr","messages":{}},
  vueI18nLoader: false,
  locales: [{"code":"fr","file":"fr.js","name":"Français"},{"code":"en","file":"en.js","name":"English"},{"code":"de","file":"de.js","name":"Deutschland"}],
  defaultLocale: "",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/home/jeanmarcphilippe/public_html/dev.jeanmarcphilippe.com/shop.digipart.fr/i18n",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","onlyOnNoPrefix":false,"onlyOnRoot":false,"useCookie":true},
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: false,
  pages: {"shops/index":{"en":"/shops","fr":"/boutiques","de":"/geschafte"},"loyalty-program/index":{"en":"/loyalty-program","fr":"/programme-fidelite","de":"/programm-fidelite"},"refer-a-friend/index":{"en":"/refer-a-friend","fr":"/parrainer-un-ami","de":"/empfehlen-sie-einen-freund"},"shop/_permalink":{"en":"/shop/:permalink","fr":"/boutique/:permalink","de":"/geschaft/:permalink"},"account/informations":{"en":"/account/informations","fr":"/account/informations","de":"/konto/information"},"content/faq/_permalink":{"en":"/content/faq/:permalink","fr":"/content/faq/:permalink","de":"/content/faq/:permalink"}},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"fr","file":"fr.js","name":"Français"},{"code":"en","file":"en.js","name":"English"},{"code":"de","file":"de.js","name":"Deutschland"}],
  localeCodes: ["fr","en","de"],
}

export const localeMessages = {
  'fr.js': () => Promise.resolve(locale77526775),
  'en.js': () => import('../../i18n/en.js' /* webpackChunkName: "lang-en.js" */),
  'de.js': () => import('../../i18n/de.js' /* webpackChunkName: "lang-de.js" */),
}
